@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	position: relative;
	height: 569px;
	padding-top: 96px;
	padding-left: 165px;
	background: linear-gradient(180deg, RGB(15 14 14 / 0%) 0%, #0e0d0d 100%), #191a22;
	box-shadow: 0 -40px 60px RGB(0 0 0 / 35%);
	-moz-box-shadow: 0 -40px 60px rgba(0, 0, 0, 0.35);
	-webkit-box-shadow: 0 -40px 60px rgba(0, 0, 0, 0.35);
	border-radius: 48px 48px 0 0;

	@include tablet {
		height: auto;
		border-radius: unset;
		padding: 48px;
	}

	@include mobile {
		padding: 32px 15px;
	}
}

.container {
	display: flex;
	max-width: 1440px;
	margin: 0 auto;

	@include tablet {
		flex-direction: column-reverse;
	}

	@include mobile-medium {
		gap: 32px;
	}
}

.heading {
	margin-bottom: 24px;
	max-width: 502px;
	font-weight: 600;
	font-size: 40px;
	line-height: 48px;
	text-transform: uppercase;
	color: $white;

	@include screen_1200 {
		font-size: 28px;
		line-height: 34px;
	}

	@include mobile {
		margin-bottom: 8px;
	}
}

.stores {
	margin-top: 48px;
	display: flex;
	gap: 24px;

	@include screen_1200 {
		flex-direction: column;
	}

	@include tablet {
		flex-direction: row;
	}

	@include mobile {
		margin-top: 24px;

		svg {
			width: 156px;
		}
	}

	@include mobile-medium {
		justify-content: space-between;
	}
}

.line::after {
	content: '\a';
	white-space: pre;
}

.content {
	position: relative;
	z-index: 2;
	max-width: 500px;

	@include screen_1200 {
		max-width: 300px;
	}

	@include tablet {
		max-width: 100%;
	}
}

.img {
	z-index: 1;
	height: 529px;

	@include tablet {
		clip-path: polygon(0 0, 100% 0, 100% 55%, 0 55%);
	}
}

.illustration {
	width: 100%;
	display: flex;
	justify-content: center;

	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		inset: 0;
		max-width: 1440px;
		margin: 0 auto;
		background-image: url('../../../assets/img/startScreenBG-1.png');
		background-repeat: no-repeat;
		background-size: 930px 830px;
		background-position: bottom 90% right 0;

		@include screen_1293 {
			background-size: 730px 630px;
		}

		@include tablet {
			height: 240px;
			background-position: top 15% center;
		}

		@include mobile-medium {
			height: unset;
			background-image: url("../../../assets/img/startImgSmall.png");
			background-position: top 0 center;
			background-size: auto;
		}
	}

	@include tablet {
		height: 240px;
	}

	@include mobile-medium {
		height: 330px;
	}
}

.cardsWrapper {
	position: relative;

	@include tablet {
		display: none;
	}
}

.buyCard,
.exchangeCard,
.securityCard {
	position: relative;
	z-index: 2;
}

.buyCard {
	top: -80px;
	right: 265px;

	@include screen_1200 {
		right: 180px;
	}
}

.exchangeCard {
	top: -90px;
	right: -110px;
}

.securityCard {
	bottom: 100px;
	right: 235px;
}

.cardsMobile {
	display: none;
	flex-direction: column;
	gap: 16px;

	@include tablet {
		display: flex;
	}
}
