@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	height: 562px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	padding: 96px 165px;
	background: linear-gradient(180deg, RGB(15 14 14 / 0%) 0%, #0e0d0d 100%), #191a22;
	box-shadow: 0 -40px 60px RGB(0 0 0 / 35%);
	-moz-box-shadow: 0 -40px 60px rgba(0, 0, 0, 0.35);
	-webkit-box-shadow: 0 -40px 60px rgba(0, 0, 0, 0.35);

	@include tablet {
		padding: 48px;
	}

	@include mobile {
		height: auto;
		padding: 32px 15px;
	}

	@include mobile-medium {
		background: linear-gradient(180deg, rgba(15, 14, 14, 0) 0%, #0E0D0D 100%), #212A70;
	}
}

.headingWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include mobile {
		max-width: 170px;
		text-align: center;
	}
}

.storesWrapper {
	display: flex;
	gap: 24px;

	@include mobile {
		svg {
			width: 156px;
		}
	}
}

.stepsWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 32px;
	width: 100%;

	@include mobile {
		flex-direction: column;

		svg {
			transform: rotate(90deg);
		}
	}
}
