@import 'styles/colors';
@import 'styles/breakpoints';

.button {
	display: flex;
	padding: 15px 32px;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	color: $white;
	background-image: linear-gradient(264.11deg, $pink -2.87%, $blue 105.13%);
	transition: all 0.3s ease;
	transition-property: transform, box-shadow;
	border: none;
	border-radius: 16px;

	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 8px 16px -8px RGB(0 0 0 / 60%);
		-moz-box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.6);
		-webkit-box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.6);
	}

	&:active {
		transform: scale(0.95);
	}

	@include mobile-medium {
		width: 100%;
	}
}
