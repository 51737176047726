@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	position: relative;
	z-index: 1;
	height: 824px;
	padding: 96px 165px;
	background: $eerie-black;
	box-shadow: 0 -40px 60px RGB(0 0 0 / 35%);
	-moz-box-shadow: 0 -40px 60px rgba(0, 0, 0, 0.35);
	-webkit-box-shadow: 0 -40px 60px rgba(0, 0, 0, 0.35);

	@include tablet {
		height: auto;
		padding: 48px;
	}

	@include mobile {
		padding: 32px 15px;
	}

	@include mobile-medium {
		background: linear-gradient(180deg, rgba(15, 14, 14, 0) 0%, #0e0d0d 100%), #242955;
	}
}

.container {
	max-width: 1110px;
	margin: 0 auto;

	@include tablet {
		max-width: 100%;
		display: flex;
		flex-direction: column-reverse;
	}
}

.text {
	font-weight: 300;
	font-size: 18px;
	line-height: 22px;
	color: $white;
}

.coinsList {
	display: flex;
	gap: 16px;

	@include mobile {
		& > div:nth-child(4),
		& > div:nth-child(5) {
			display: none;
		}
	}
}

.smallText {
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: $white-50;
}

.imgWrapper {
	width: 100%;
  z-index: 0;
	@include tablet {
		height: 450px;
	}

	@include mobile-medium {
		height: 475px;
	}

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		max-width: 1440px;
		margin: 0 auto;
		background-image: url('../../../assets/img/nftImg1.png');
		background-repeat: no-repeat;
		background-size: 565px 628px;
		background-position: bottom 90% right 10%;

		@include screen_1293 {
			background-position: bottom 50% right 0;
		}

		@include screen_1200 {
			background-size: 365px 428px;
			background-position: bottom 70% right 10%;
		}

		@include tablet {
			height: auto;
			background-position: top 5% center;
		}

		@include mobile {
			background-image: url('../../../assets/img/nftImgSmall1.png');
			background-size: 305px 428px;
		}

		@include mobile-medium {
			background-size: 305px 428px;
			background-position: top 10% center;
			height: auto;
		}
	}
}

.contentWrapper {
    position: relative;
	max-width: 560px;
	z-index: 2;
	@include screen_1293 {
		max-width: 450px;
	}

	@include tablet {
		max-width: 100%;
	}
}

.buttonWrapper {
	margin-top: 32px;
	width: fit-content;
	border-radius: 16px;
}

.downloadWrapper {
	padding-bottom: 31px;
	border-bottom: 1px dashed $grey;

	@include mobile-medium {
		& > div {
			width: 100%;

			button {
				width: 100%;
			}
		}
	}
}

.explanationWrapper {
	margin-top: 32px;
}

.coinsWrapper {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	gap: 2px;
}
