@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	width: 140px;
	padding: 15px;
	text-align: center;
	background: linear-gradient(
		264.11deg,
		RGB(255 19 109 / 10%) -2.87%,
		RGB(69 63 214 / 10%) 105.13%
	);
	backdrop-filter: blur(15px);
	border: 1px solid $pink-15;
	border-radius: 16px;

	@include tablet {
		width: auto;
		display: flex;
		gap: 16px;
		align-items: center;
	}
}

.title {
	margin-top: 16px;
	margin-bottom: 8px;
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	color: $white-90;

	@include tablet {
		text-align: left;
	}
}

.text {
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: $white-90;

	@include tablet {
		text-align: left;
	}
}
