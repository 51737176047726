@import 'styles/colors';

.wrapper {
	max-width: 147px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
}

.text {
	max-width: 90px;
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	letter-spacing: 0.02em;
	color: $white-90;
}

.number {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 54px;
	height: 54px;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	color: RGB(255 255 255 / 90%);
	box-sizing: border-box;
	padding: 16px;
	background: linear-gradient(
		264.11deg,
		RGB(255 19 109 / 10%) -2.87%,
		RGB(69 63 214 / 10%) 105.13%
	);
	border-radius: 50%;
}
