@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	position: relative;
	z-index: 1;
	padding: 96px 165px;
	height: 767px;
	background: $eerie-black;
	box-shadow: 0 -40px 60px RGB(0 0 0 / 35%);
	-moz-box-shadow: 0 -40px 60px rgba(0, 0, 0, 0.35);
	-webkit-box-shadow: 0 -40px 60px rgba(0, 0, 0, 0.35);

	@include screen_1200 {
		height: 550px;
	}

	@include tablet {
		height: auto;
		padding: 48px;
	}

	@include mobile {
		padding: 32px 15px;
	}

	@include mobile-medium {
		background: linear-gradient(180deg, rgba(15, 14, 14, 0) 0%, #0e0d0d 100%), #191a22;
	}
}

.container {
	display: flex;
	max-width: 1100px;
	margin: 0 auto;

	@include tablet {
		flex-direction: column-reverse;
		max-width: 100%;
	}
}

.content {
	position: relative;
	max-width: 560px;
	z-index: 2;

	@include tablet {
		max-width: 100%;
	}
}

.buttonWrapper {
	margin-top: 32px;
	width: fit-content;
	border-radius: 16px;

	@include mobile-medium {
		width: 100%;
	}
}

.sectionWrapper {
	max-width: 440px;
}

.optionsWrapper {
	margin-top: 32px;
	display: grid;
	grid-template-columns: repeat(2, 268px);
	grid-gap: 16px 24px;

	@include screen_1200 {
		grid-template-columns: repeat(2, 200px);
	}

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 12px;
	}
}

.imgWrapper {
	width: 100%;

	@include tablet {
		height: 350px;
	}

	@include mobile-medium {
		height: 320px;
	}

	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		inset: 0;
		max-width: 1440px;
		margin: 0 auto;
		background-image: url('../../../assets/img/accessScreenMobile.png');
		background-repeat: no-repeat;
		background-size: 565px 628px;
		background-position: bottom 90% right 10%;

		@include screen_1293 {
			background-size: 390px 428px;
			background-position: bottom 50% right 10%;
		}

		@include tablet {
			height: 350px;
			background-position: top center;
		}

		@include mobile-medium {
			background-image: url('../../../assets/img/accessImgSmall.png');
			background-size: auto;
			height: auto;
		}
	}
}
