@import '../../../../styles/colors';
@import '../../../../styles/breakpoints';

.wrapper {
	padding: 64px;
	border: 1px solid RGB(0 0 0 / 15%);
	width: 100%;

	@include mobile-medium {
		padding: 16px;
	}
}

.heading {
	margin-bottom: 30px;
	font-weight: 800;
	font-size: 32px;
	letter-spacing: -0.015em;
	text-transform: uppercase;
	color: $white;
}

.columnHead {
	font-weight: 300;
	font-size: 16px;
	letter-spacing: -0.015em;
	color: $white-80;
}

.columnHeadWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.rowHead {
	font-weight: 300;
	font-size: 12px;
	letter-spacing: -0.015em;
	color: $white-50;
}

.text {
	font-weight: 300;
	font-size: 12px;
	letter-spacing: -0.015em;
	color: $white;
}

.table {
	border-spacing: 0 24px;
	width: 100%;
	background: $white-10;
	border-radius: 45px;
}

.column {
	width: 120px;
}

.value {
	font-weight: 500;
	font-size: 12px;
	letter-spacing: -0.015em;
	text-align: center;
	color: $white;
}
