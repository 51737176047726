@import 'styles/breakpoints';
@import 'styles/colors';

.wrapper {
	display: flex;
	align-items: center;
	margin: 0 auto;
	padding: 28px 48px;
	max-width: 1334px;

	@include tablet {
		justify-content: space-between;
	}

	@include mobile {
		padding: 15px;
	}
}

.logo {
	width: 127px;
	height: 40px;

	@include mobile {
		width: auto;
		height: 24px;
	}
}

.nav {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;

	@include tablet {
		display: none;
	}
}

.list {
	display: flex;
	gap: 24px;
}

.buttons {
	display: flex;
	gap: 16px;
}

.langButton {
	@include tablet {
		display: none;
	}
}

.startedButton {
	display: block;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	line-height: 19px;
	color: $white;
	transition: all 0.3s ease;
	transition-property: transform, box-shadow;
	border: none;
	border-radius: 16px;

	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 8px 16px -8px RGB(0 0 0 / 60%);
		-moz-box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.6);
		-webkit-box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.6);
	}

	&:active {
		transform: scale(0.95);
	}

	@include mobile {
		display: none;
	}
}

.logoWrapper {
	position: relative;
	display: flex;
	gap: 40px;
}

.burgerLinks {
	display: flex !important;
	flex-direction: column;
	align-items: center;
	gap: 32px;
}

.burgerStores {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.burgerWrapper {
	display: none;

	@include tablet {
		display: block;
	}
}
