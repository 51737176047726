@import 'styles/colors';

.wrapper {
	padding: 8px 0;
	display: flex;
	align-items: center;
	gap: 8px;
}

.text {
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	color: $white;
}

.caption {
	font-weight: 300;
	font-size: 9px;
	line-height: 11px;
	color: $white-50;
}

.icon {
	width: 24px;
	height: 24px;
}
