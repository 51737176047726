@import 'styles/breakpoints';

.link {
	letter-spacing: -0.015em;

	@include tablet {
		font-size: 12px !important;
		line-height: 14px !important;
	}
}
