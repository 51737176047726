@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	position: relative;
	height: 747px;
	padding: 96px 165px;
	background: linear-gradient(180deg, RGB(15 14 14 / 0%) 0%, #0e0d0d 100%), #191a22;

	@include tablet {
		padding: 48px;
	}

	@include mobile {
		padding: 32px 15px;
	}

	@include mobile-medium {
		background: linear-gradient(180deg, rgba(15, 14, 14, 0) 0%, #0e0d0d 100%), #262e6c;
	}
}

.container {
	display: flex;
	max-width: 1110px;
	margin: 0 auto;

	@include tablet {
		flex-direction: column;
		max-width: auto;
	}
}

.imgWrapper {
	width: 100%;

	@include tablet {
		height: 280px;
	}

	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		inset: 0;
		max-width: 1440px;
		margin: 0 auto;
		background-image: url('../../../assets/img/browserImg1.png');
		background-repeat: no-repeat;
		background-size: 565px 565px;
		background-position: bottom 90% left 10%;

		@include screen_1293 {
			background-size: 390px 390px;
		}

		@include tablet {
			height: 280px;
			background-position: top center;
		}

		@include mobile-medium {
			height: auto;
			background-size: auto;
			background-image: url('../../../assets/img/browserImgSmall1.png');
		}
	}
}

.optionsWrapper {
	margin: 32px 0;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.content {
	position: relative;
	z-index: 2;
	max-width: 560px;

	@include screen_1293 {
		margin-top: 92px;
	}

	@include screen_1200 {
		max-width: 450px;
	}

	@include tablet {
		margin-top: 0;
		max-width: unset;
	}
}

.buttonWrapper {
	width: fit-content;
	border-radius: 16px;

	@include mobile-medium {
		width: 100%;

		button {
			width: 100%;
		}
	}
}
