$black: #000;
$black-10: rgba($black, 0.1);
$black-26: rgba($black, 0.26);
$black-30: rgba($black, 0.3);
$black-60: rgba($black, 0.6);
$black-modal: #100f0f;
$white: #fff;
$white-90: rgba($white, 0.9);
$white-80: rgba($white, 0.8);
$white-50: rgba($white, 0.5);
$white-15: rgba($white, 0.15);
$white-10: rgba($white, 0.10);
$blue: #453fd7;
$blue-15: rgba($blue, 0.15);
$pink: #ff136d;
$pink-15: rgba($pink, 0.15);
$eerie-black: #191a22;
$grey: #b6b6b6;
$red: #d33030;
$green: #12bc56;
