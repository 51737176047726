@import 'styles/breakpoints';

.link {
	cursor: pointer;
	display: flex;
	align-items: flex-start;
	gap: 4px;
	letter-spacing: -0.015em;

	@include tablet {
		font-size: 12px !important;
		line-height: 14px !important;
	}
}
