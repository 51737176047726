@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	position: relative;
	padding: 96px 165px;
	height: 892px;
	background: $eerie-black;

	@include screen_1293 {
		height: 980px;
	}

	@include screen_1200 {
		height: 900px;
	}

	@include tablet {
		height: auto;
		padding: 48px;
	}

	@include mobile {
		padding: 32px 15px;
	}

	@include mobile-medium {
		background: linear-gradient(180deg, rgba(15, 14, 14, 0) 0%, #0E0D0D 100%), #24294D;
	}
}

.container {
	max-width: 1100px;
	margin: 0 auto;
}

.calculatorWrapper {
	display: flex;
	justify-content: space-between;

	@include tablet {
		flex-direction: column-reverse;
	}
}

.calculatorImg {
	width: 100%;

	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		inset: 0;
		max-width: 1440px;
		margin: 0 auto;
		background-image: url('../../../assets/img/stakingCalculator.png');
		background-repeat: no-repeat;
		background-size: 381px 318px;
		background-position: bottom 80% right 10%;

		@include tablet {
			height: 350px;
			background-position: top 65% center;
		}

		@include mobile-medium {
    		background-position: top 80% center;
		}
	}

	@include tablet {
		height: 330px;
	}
}

.contentWrapper {
	max-width: 633px;

	@include screen_1293 {
		max-width: 450px;
	}

	@include screen_1200 {
		max-width: 400px;
	}

	@include tablet {
		max-width: 100%;
	}
}

.optionsWrapper {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	@include tablet {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
	}
}

.coinsSection {
	display: flex;
	justify-content: space-between;

	@include tablet {
		flex-direction: column;
		align-items: center;
	}
}

.coinsImgWrapper {
	width: 100%;

	@include tablet {
		position: relative;
		height: 350px;
	}

	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		inset: 0;
		max-width: 1440px;
		margin: 0 auto;
		background-image: url('../../../assets/img/coinMobile.png');
		background-repeat: no-repeat;
		background-size: 470px 628px;
		background-position: bottom -50% left 10%;

		@include tablet {
			height: 350px;
			background-position: top center;
		}

		@include mobile-medium {
			height: auto;
			background-size: auto;
			background-image: url('../../../assets/img/stakingImgSmall.png');
		}
	}
}

.captionWrapper {
	padding-top: 80px;
	padding-bottom: 15px;
	border-bottom: 1px dashed $grey;

	@include tablet {
		padding-top: 20px;
	}
}

.text {
	font-weight: 300;
	font-size: 18px;
	line-height: 22px;
	color: $white;

	@include tablet {
		font-size: 14px;
		line-height: 17px;
	}
}

.coinsWrapper {
	display: flex;
	flex-direction: column;
	gap: 2px;
	margin-top: 16px;
}

.smallText {
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	color: $white-50;
}

.coinsList {
	display: flex;
	gap: 16px;

	@include mobile {
		& > div:nth-child(4),
		& > div:nth-child(5) {
			display: none;
		}
	}
}
