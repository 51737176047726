@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	height: 275px;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding: 48px 165px;
	display: flex;
	justify-content: space-between;
	background: $black;

	@include screen_1293 {
		height: auto;
	}

	@include screen_1200 {
		padding: 48px;
	}

	@include mobile {
		padding: 15px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 16px;
		grid-row-gap: 32px;
	}
}

.logoWrapper {
	width: 92px;
	height: 29px;
}

.socialWrapper {
	display: flex;
	gap: 16px;
}

.link {
	font-weight: 300;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.015em;
	text-align: left;
	color: $white;
}

.block {
	display: flex;
	flex-direction: column;
	gap: 16px;

	@include mobile {
		&:nth-child(1) {
		  	grid-column-start: 1;
		  	grid-column-end: 3;
			flex-direction: row;
			justify-content: space-between;

			& > button {
				display: none;
			}
		}

		&:nth-child(2) {
			grid-column: 1;
		}
	}
}

.blockHeading {
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: -0.015em;
	text-transform: capitalize;
	color: $white;
}

.termsWrapper {
	display: none;
	grid-column-start: 1;
	grid-column-end: 3;

	@include mobile {
		display: flex;
		gap: 16px;
	}
}

.overlayCustom {
	position: fixed;
	inset: 0;
	background: $black-60;
	z-index: 2;
}

.contentCustom {
	height: max-content;
	position: absolute;
	inset: 10%;
	background: $black-modal;
	border-radius: 16px;
	overflow: auto;
	outline: none;

	@include tablet {
		inset: 5%;
	}

	@include mobile {
		inset: unset;
		top: 25px;
		bottom: 50px;
		left: 20px;
		right: 20px;
	}
}

.modalContent {
	padding: 48px;
	max-height: 50vh;
	overflow-y: scroll;

	@include tablet {
		padding: 15px;
	}
}

.modalH2 {
	font-weight: 500;
	font-size: 36px;
	line-height: 43px;
	color: $white;

	@include tablet {
		font-size: 24px;
		line-height: 29px;
	}
}

.modalH3 {
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: $white;

	@include tablet {
		font-size: 24px;
		line-height: 29px;
	}
}

.modalH4 {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: $white;

	@include tablet {
		font-size: 18px;
		line-height: 22px;
	}
}

.modalText {
	margin-bottom: 32px;
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: 0.02em;
	text-align: justify;
	color: $white-90;
}

.modalHeading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 48px;
	border-bottom: 1px solid grey;

	svg {
		cursor: pointer;
	}

	@include tablet {
		padding: 24px 15px;
	}
}

.modalButtons {
	display: flex;
	gap: 16px;
	align-items: center;
	padding: 24px 48px;
	border-top: 1px solid grey;

	@include tablet {
		padding: 24px 15px;
	}
}

.modalAccept {
	width: 100%;
	padding: 15px 32px;
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	color: $white;
	background-image: linear-gradient(264.11deg, $pink -2.87%, $blue 105.13%);
	transition: all 0.3s ease;
	transition-property: transform, box-shadow;
	border: none;
	border-radius: 16px;

	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 8px 16px -8px RGB(0 0 0 / 60%);
		-moz-box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.6);
		-webkit-box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.6);
	}

	&:active {
		transform: scale(0.95);
	}
}

.modalCancel {
	width: 100%;
	padding: 15px;
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
	text-transform: capitalize;
	color: $white;
	letter-spacing: -0.015em;
	border: 1px solid $white;
	border-radius: 16px;
}
