@import 'styles/breakpoints';

/* Position and sizing of burger button */
.bm-burger-button {
	position: absolute;
	width: 24px;
	height: 24px;
	top: 10px;

	@include mobile {
		top: unset;
	}
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
	top: 15px !important;
	left: 15px !important;
	right: unset !important;
	height: 24px;
	width: 24px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;

	@include mobile {
		width: 100% !important;
	}
}

/* General sidebar styles */
.bm-menu {
	background: #1c1a45;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
	fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
	color: #b8b7ad;
	padding: 0.8em;
}

/* Individual item */
.bm-item {
	display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
	background: RGB(0 0 0 / 30%);
}
