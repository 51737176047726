@import 'styles/colors';
@import 'styles/breakpoints';

.wrapper {
	position: relative;
	z-index: 1;
	padding: 96px 165px;
	height: 895px;
	background: $eerie-black;

	@include screen_1293 {
		height: auto;
	}

	@include tablet {
		height: auto;
		padding: 48px;
	}

	@include mobile {
		padding: 15px;
	}

	@include mobile-medium {
		background: linear-gradient(180deg, rgba(15, 14, 14, 0) 0%, #0E0D0D 100%), #21243B;
	}
}

.container {
	display: flex;
	max-width: 1100px;
	margin: 0 auto;

	@include tablet {
		flex-direction: column;
		max-width: 100%;
	}
}

.content {
	position: relative;
	z-index: 2;
	max-width: 560px;

	@include screen_1293 {
		margin-top: 92px;
	}

	@include screen_1200 {
		max-width: 450px;
	}

	@include tablet {
		margin-top: 0;
		max-width: unset;
	}
}

.sectionWrapper {
	max-width: 440px;
}

.optionsWrapper {
	margin-top: 32px;
	display: grid;
	grid-template-columns: repeat(2, 268px);
	grid-gap: 16px 24px;

	@include tablet {
		grid-template-columns: repeat(2, 1fr);
	}

	@include mobile {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 12px;
	}
}

.imgWrapper {
	width: 100%;

	@include tablet {
		height: 280px;
	}

	@include mobile-medium {
		height: 432px;
	}

	&::before {
		content: '';
		position: absolute;
		z-index: 1;
		inset: 0;
		max-width: 1440px;
		margin: 0 auto;
		background-image: url('../../../assets/img/assetsScreenMobile.png');
		background-repeat: no-repeat;
		background-size: 440px 823px;
		background-position: bottom 155% left 10%;

		@include screen_1293 {
			background-size: 352px 658px;
			background-position: bottom 0% left 10%;
		}

		@include tablet {
			height: 280px;
			background-position: top center;
		}

		@include mobile-medium {
			height: auto;
			background-size: auto;
			background-image: url("../../../assets/img/assetsImgSmall.png");
			background-position: top 0 center;
		}
	}
}

.img {
	width: inherit;
}

.gradient {
	margin-top: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
	width: 100%;
	padding: 24px;
	background: linear-gradient(
		264.11deg,
		RGB(69 63 214 / 30%) -2.87%,
		RGB(255 19 109 / 30%) 105.13%
	);
	border-radius: 16px;

	@include tablet {
		padding: 0;
		justify-content: flex-start;
		background: none;

		div:first-child {
			display: none;
		}
	}
}

.heading {
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: $white;

	@include screen_1200 {
		font-size: 16px;
		line-height: 19px;
	}

	@include tablet {
		display: none;
	}
}

.text {
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	color: $white-90;

	@include screen_1200 {
		font-size: 14px;
		line-height: 16px;
	}

	@include tablet {
		display: none;
	}
}

.captionWrapper {
	max-width: 310px;
}

.button {
	margin-left: 8px;
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 15px 23px 15px 15px;
	font-weight: 400;
	font-size: 18px;
	line-height: 21px;
	background: transparent;
	border: 1px solid $white-15;
	border-radius: 16px;
	color: #fff;

	@include mobile {
		margin-left: 0;
		width: 100%;
		justify-content: center;
	}
}
