@import 'fonts';
@import 'colors';
@import 'burger';

// @import 'breakpoints';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	font-family: $rubik-font;
}

html,
body {
	scroll-behavior: smooth;
}

body {
	background: $black;
}

ul {
	list-style-type: none;
}

a {
	cursor: pointer;
	color: unset;
	text-decoration: none;
}

a[data-link-disabled='disabled'] {
	pointer-events: none;
}

button {
	cursor: pointer;
	background: none;
	border: none;
	outline: none;
}

html,
body {
	position: relative;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
}

.text {
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	color: $white;
}

.sectionHeading {
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 36px;
	line-height: 43px;
	color: $white;

	@include screen_1200 {
		font-size: 24px;
		line-height: 29px;
	}
}

.sectionHighlight {
	color: $pink;
}

.textHeading {
	margin-bottom: 2px;
	font-weight: 500;
	font-size: 24px;
	line-height: 29px;
	color: $white;
}

.textSecondary {
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	color: $white-90;

	@include screen_1200 {
		font-size: 14px;
		line-height: 17px;
	}
}

.lineBreakAfter::after {
	content: '\a';
	white-space: pre;
}

.lineBreakBefore::before {
	content: '\a';
	white-space: pre;
}
